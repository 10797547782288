import { render, staticRenderFns } from "./waterStationPST.vue?vue&type=template&id=6c5bc618&scoped=true&"
import script from "./waterStationPST.vue?vue&type=script&lang=js&"
export * from "./waterStationPST.vue?vue&type=script&lang=js&"
import style0 from "./waterStationPST.vue?vue&type=style&index=0&id=6c5bc618&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c5bc618",
  null
  
)

export default component.exports