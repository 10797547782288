<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>水站进货统计表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>
    <div style="
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        padding: 20px;
        border-bottom: 1px solid #e5e5e5;
        white-space: nowrap;
      ">
      <el-col :span="7" style="display: flex; flex-wrap: nowrap">
        <span>使用时间：</span>
        <el-date-picker type="date" v-model="query.firstTime" style="width: 35%"></el-date-picker>
        <span style="margin: 0 5px">-</span>
        <el-date-picker type="date" v-model="query.lastTime" style="width: 35%"></el-date-picker>
      </el-col>
      <el-col :span="5">
        <span>选择水站：</span>
        <el-select v-model="query.station" clearable filterable placeholder="选择水站" style="width: 60%">
          <el-option v-for="item in stations" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <span>配送司机：</span>
        <el-select v-model="option2" clearable filterable placeholder="配送司机" style="width: 60%">
          <el-option v-for="item in option2es" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <span>选择商品：</span>
        <el-select v-model="option3" clearable filterable placeholder="选择商品" style="width: 60%">
          <el-option v-for="item in option3es" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-col>
    </div>
    <div style="
        padding: 5px 20px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
      ">
      <el-button type="success" icon="iconfont icon-sousuo" @click="hanldeQuery">查询</el-button>
      <el-button type="success" icon="el-icon-delete">清除</el-button>
      <el-button type="primary" icon="iconfont icon-daochuFile" style="font-size: 14px" size="mini"
        @click="dialogVisible = !dialogVisible">导出报表</el-button>
    </div>
    <div style="padding: 20px">
      <el-table :data="tableData" border show-summary style="width: 100%">
        <el-table-column prop="stationName" label="站名"> </el-table-column>
        <el-table-column prop="name" label="品名"> </el-table-column>
        <el-table-column prop="specification" label="规格"> </el-table-column>
        <el-table-column prop="numbel" label="数量"> </el-table-column>
        <el-table-column prop="purchasingCost" label="进货价">
        </el-table-column>
        <el-table-column prop="money" label="金额"> </el-table-column>
        <el-table-column prop="details" label="详情"> </el-table-column>
      </el-table>
      <div class="tableBottom">
        <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
          tableFinshNum
        }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
        <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
          tableFinshNum
        }}&nbsp;项</span>
        <div style="display: flex; justify-content: flex-end; align-items: center">
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      query: {
        firstTime: "",
        lastTime: "",
        station: "",
        // search: "",
        // currentPage: "",
        // currentLength: ""
      },
      stations: [],
      startDate: "",
      endDate: "",
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      pickerOptions2: {
        disabledDate: (time) => {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.startDate.getTime()
          );
        },
      },
      option1: "",
      option1es: [],
      option2: "",
      option2es: [],
      option3: "",
      option3es: [],

      tableData: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  async mounted() {
    let res = (await this.$http.post("/Client/commercial_owner/lst")).data.data;
    this.stations = res.map(el => el.enterprise_name);
    this.hanldeQuery();
    // if (this.tableData.length == 0) {
    //   this.isResult = false;
    // } else {
    //   this.isResult = true;
    //   this.tableFinshNum = this.tableData.length;
    // }
  },
  computed: {},
  methods: {
    async hanldeQuery() {
      // let query = this.query;
      // let res = (await this.$http.post("", query)).data.data;
      // this.tableData = res;
    },
    reload() {
      location.reload();
    },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
</style>
